/** @format */

import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, Navigate } from "react-router-dom";
export default function PrivateComponents(props) {
  let location = useLocation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return props.children;
}
