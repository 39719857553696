/** @format */

import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form, Button, Row, Col, Card, ListGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Profile = () => {
  const { user: currentUser } = useSelector((state) => {
    console.log(state);
    return state.auth;
  });

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Row>
      <Col md={3}>
        <h2>User Profile</h2>
        <div>
          <span>Profile : {currentUser.user.username}</span>
          <br />
          <span>Email : {currentUser.user.email}</span>
        </div>
      </Col>
    </Row>
    // <div className="container">
    //   <header className="jumbotron">
    //     <h3>
    //       <strong>{currentUser.user.username}</strong> Profile
    //     </h3>
    //   </header>
    //   <p>
    //     <strong>Token:</strong> {currentUser.token.substring(0, 20)} ...{" "}
    //     {currentUser.token.substr(currentUser.token.length - 20)}
    //   </p>
    //   <p>
    //     <strong>Id:</strong> {currentUser.user.id}
    //   </p>
    //   <p>
    //     <strong>Email:</strong> {currentUser.user.email}
    //   </p>
    // </div>
  );
};

export default Profile;
