/** @format */

import React, { Component } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { Button } from "react-bootstrap";
import "./room.style.css";
// const roomName = "test1";
export class Room extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.imgRef = React.createRef();
    this.state = {
      src: null,
      pause: true,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleshutdown = this.handleshutdown.bind(this);
    this.handlereset = this.handlereset.bind(this);
  }
  client = new W3CWebSocket(`wss://api.nozzlecam.com/ws?b=0&id=12345`);
  b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  sendMsg(cmd) {
    const data = {
      action: "cmd",
      message: cmd,
    };
    this.client.send(JSON.stringify(data));
  }
  componentDidMount() {
    this.client.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    this.client.onmessage = (message) => {
      const jsonData = JSON.parse(message.data);
      // console.log(jsonData);
      const action = jsonData["action"];
      if (action === "new-watcher") {
        // createOffer(peerID, peer_channel_name);
        console.log(action);
      }
      if (action === "new-broadcast") {
        // createAnswer(offer, peerID, peer_channel_name);
        console.log(action);
      }
      if (action === "stream") {
        // console.log(jsonData);
        const vdo = jsonData["message"];
        if (this.state.pause) {
          this.setState({
            src: URL.createObjectURL(this.b64toBlob(vdo)),
          });
        }
      }
    };
  }
  handleClick(e) {
    e.preventDefault();
    console.log(this.state.pause);
    this.setState({
      pause: !this.state.pause,
    });
  }
  handleshutdown(e) {
    e.preventDefault();
    this.sendMsg(1);
  }
  handlereset(e) {
    e.preventDefault();
    this.sendMsg(2);
  }
  render() {
    return (
      <div className="room-container">
        <div className="video-container">
          <img
            className="video-player"
            ref={this.imgRef}
            src={this.state.src}
            onClick={this.handleClick}
            alt="Video player"
          />
        </div>
        <div className="cmd-container">
          <Button
            className="btn-cmd"
            type="submit"
            variant="primary"
            onClick={this.handleshutdown}
          >
            Shutdown
          </Button>
          <Button className="btn-cmd" type="submit" onClick={this.handlereset}>
            Reset
          </Button>
        </div>
      </div>
    );
  }
}

export default Room;
